import styled from "styled-components"

const Section = styled.section`
  padding-top: 5rem;
  padding-bottom: 5rem;

  ${({ dark }) =>
    dark
      ? `
    background: #211e1f;
    color: #fff;
    `
      : null};
`

export const SectionTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 3rem;
  text-align: center;
  font-size: 32px;
  text-transform: uppercase;
  font-weight: 400;
`

export const SectionText = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
`

export default Section
