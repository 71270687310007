import React from "react"
import Layout from "../components/Layout"
import Container from "../components/Container"
import Section, { SectionTitle } from "../components/Section"
import styled from "styled-components"

const EmbeddedMap = ({ query, title, ...props }) => (
  <iframe
    title={title}
    src={`https://maps.google.com/maps?q=${query}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
    frameBorder="0"
    scrolling="no"
    marginHeight="0"
    marginWidth="0"
    style={{ background: "#f1f1f1", height: "300px", width: "100%" }}
    {...props}
  />
)

const EventContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  min-height: 300px;
  text-align: right;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    text-align: left;
  }
`
const Description = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`

const EventsPage = () => (
  <Layout title="Events">
    <Section id="wedding">
      <Container pad>
        <SectionTitle>Wedding and Reception</SectionTitle>
        <EventContainer>
          <Description>
            <p>May 28th, 2022 at 4:30 - 11:00 PM</p>
            <p>Ecotrust building in Portland</p>
            <p>
              This event is for fully-vaccinated guests only. <br />
              Ecotrust is an indoor event space, and we won't be requiring
              masks, but you are welcome to wear one if you would prefer.
            </p>
            <b>Parking</b>
            <p>
              Street parking around the venue has a two-hour limit, so if you
              are planning on driving, park in the lot to the East of the
              Ecotrust building. Payment in that lot requires the{" "}
              <a href="https://reeftechnology.com/parking-solutions/download-reef-mobile-app">
                REEF app
              </a>
              . Enter into the Ecotrust building from Irving Street.
            </p>
            <b>Accessible Parking</b>
            <p>
              There are a limited number of accessible parking spots in the
              Ecotrust parking lot (on the West side of the building). Some
              spots will be marked "Reserved for X", but those are available for
              us to use today. There is a door directly into the Irving Street
              Studio from the parking lot, to the right of the main building
              entrace.
            </p>
          </Description>
          <EmbeddedMap
            title="Ecotrust Building"
            query="ecotrust building portland"
          />
        </EventContainer>
      </Container>
    </Section>
    <Section id="after-party" dark>
      <Container pad>
        <SectionTitle>After Party</SectionTitle>
        <EventContainer>
          <Description>
            <p>Events at the Ecotrust building will end at 11:00.</p>
            <p>
              If you're looking to continue partying, we recommend the Paymaster
              Lounge half a mile away.
            </p>
          </Description>
          <EmbeddedMap title="Paymaster" query="paymaster lounge portland" />
        </EventContainer>
      </Container>
    </Section>
  </Layout>
)

export default EventsPage
